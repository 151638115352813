<template>
  <div :class="['detail-row', `detail-row-${alignment}`]">
    <div class="detail-row-content">
      <span class="detail-row-name">
        <slot name="name" />
      </span>
      <span class="detail-row-notes">
        <slot name="note" />
      </span>
    </div>
    <slot name="extra" />
  </div>
</template>

<script>
  export default {
    props: {
      alignment: {
        type: String,
        default: "top",
        validator(value) {
          return ["top", "center"].includes(value)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .detail-row {
    padding: $base-spacing * 2 0;
    display: flex;
    border-bottom: 1px solid var(--border);
    text-align: right;
    container-type: inline-size;

    &-top {
      align-items: flex-start;
    }
    &-center {
      align-items: center;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .detail-row-content {
    min-width: $base-spacing * 26;
    flex: 1;
    text-align: left;
  }
  .detail-row-name {
    font-weight: bold;
    margin-right: 1ex;
  }
  .detail-row-notes {
    color: $gray-text;
    margin-right: 1ex;
    .cs-dark & {
      color: white;
    }
    @container (width < 300px) {
      display: block;
    }
  }
</style>
